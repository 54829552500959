import api from '../../utils/apiFetch';
import serialize from '../../utils/serialize';

export default {
  info: (payload) => api.get(`enterprises/info`, payload),
  getGiftCards: (params) =>
    api.get(`enterprises/gift-cards${serialize(params)}`),
  getTransactions: (params) =>
    api.get(`enterprises/transactions${serialize(params)}`),
  getBulkTransactions: (params) =>
    api.get(`enterprises/bulk-transactions${serialize(params)}`),
  setEnterpriseImage: (payload) =>
    api.file(`enterprises/creativeimage`, payload),
  updateEnterpriseImage: (payload) =>
    api.put(`enterprises/image/${payload.id}`, payload),
  getHistoryRedemptionsApi: (params) =>
    api.get(`enterprises/redemptions?${serialize(params)}`),
};
