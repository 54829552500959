import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Button } from '@material-ui/core';

const DownloadCSV = ({
  handleDownload,
  data,
  refLink,
  filename,
  text,
  children,
  ...rest
}) => {
  return (
    <>
      {children || (
        <Button
          onClick={handleDownload}
          color="primary"
          variant="contained"
          {...rest}
        >
          {text}
        </Button>
      )}
      <CSVLink
        data={data}
        filename={`${filename}.csv`}
        className="hidden"
        ref={refLink}
        target="_blank"
      />
    </>
  );
};

DownloadCSV.propTypes = {
  handleDownload: PropTypes.func.isRequired,
  refLink: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  filename: PropTypes.string.isRequired,
  text: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

DownloadCSV.defaultProps = {
  text: 'Download CSV',
};

export default DownloadCSV;
